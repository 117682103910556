import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import ProductPanel from "../components/organisms/product-panel";
// import CodeBlock from "../components/atoms/code-block";
const CodeBlock = loadable(() => import("../components/atoms/code-block"));

function DesignProductPanel({ props }) {
  const designProps = props;

  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Product Panel" />
      <h2>Product Panel</h2>

      <p className="design__text p2">
        The product panel includes product details, options, and a call to
        action button to add to cart.
      </p>
      <div className="design__stories">
        <div className="design__story-section">
          <DesignStory
            title="Product Panel"
            component={ProductPanel}
            type="media"
            props={designProps}
            wrapperComponent={LatticeGrid}
            wrapperProps={{
              fullWidth: true,
              innerGrid: true,
              className: "product-hero",
            }}
          />
          <p className="design__text p2">
            The product panel component has a prop named extras that can pass
            storeContext to manage global state, constants, and environment
            variables:
          </p>
          <CodeBlock language="jsx">
            {`extras={{
  storeContext: StoreContext,
  ...CONSTANTS,
  OOS_EMAIL_LIST: process.env.OOS_EMAIL_LIST,
  PRE_LAUNCH_LIST: process.env.PRE_LAUNCH_LIST,
  FOOTER_EMAIL_LIST: process.env.FOOTER_EMAIL_LIST,
  DEV: process.env.DEV,
  KLAVIYO_API_KEY: process.env.KLAVIYO_API_KEY,
}}`}
          </CodeBlock>

          <p className="design__text p2">
            The product panel also includes props in the form of functions that
            handle when the state of options change, a item is out of stock,
            when the call to action button is clcked, and other events:
          </p>
          <CodeBlock language="jsx">
            {`setActiveParams={{
  quantity: setQuantity,
  size: setSize,
  firmness: setFirmness,
  color: setColor,
  value: setValue,
}}
setShowOOS={updateShowOOS}
setShowDiscount={updateShowDiscount}
setShowDetails={setDetailsOpen}}
atcAction={setAtcAction}`}
          </CodeBlock>
        </div>
      </div>
    </div>
  );
}

DesignProductPanel.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

DesignProductPanel.defaultProps = {
  props: {},
};

export default DesignProductPanel;
